import i18n from '@/i18n.js'
import store from '@/modules/Shared/Store/store'

const donutStatistics = ['tracked_distance_per_difficulty']
const translationsPrefix = {
  tracked_distance_per_difficulty: 'facilities.difficulty.'
}

const buildStatisticData = async (statisticKey, resourceType, resourceUuid = null) => {
  const statistics = store.getters['statistics/statistic'](statisticKey, resourceType, resourceUuid)

  if (statistics.length === 0)  {
    return null
  }

  const statistic = statistics[0];
  const translationKey = statistic.statistic ? statistic.statistic.key : statistic.accumulated.key
  const graphType = donutStatistics.includes(statisticKey) ? 'donut' : 'line';

  let units = statistic.statistic ? statistic.statistic.units : statistic.accumulated.units
  const nameUnits = units ? ` (${units})` : ''

  return {
    name: () => i18n.t(translationKey) + ` ${nameUnits}`,
    type: graphType,
    resource: {
      uuid: statistic.resourceUuid,
      type: statistic.resourceType,
      name: statistic.resourceName,
    },

    daily: generateGranularityStatistic(graphType, statistic.statistic, statistic.statistic_last_season, statistic.statistic_last_last_season, translationsPrefix[statisticKey]),
    season: generateGranularityStatistic(graphType, statistic.accumulated, statistic.accumulated_last_season, statistic.accumulated_last_last_season, translationsPrefix[statisticKey]),
    total: getTotal(statistic.total),
  }
}

const generateGranularityStatistic = (graphType, current, lastSeason, lastLastSeason, translationPrefix = null) => {
  if (!current) return null;
  const structure = {graph: null, card:  null}
  if (current.points.length === 1) {
    structure.card = getCardData(current, lastSeason, lastLastSeason)
  } else {
    structure.chart = getChartData(graphType, current, lastSeason, lastLastSeason, translationPrefix)
  }

  return structure
}

const getChartData = (graphType, current, lastSeason, lastLastSeason, translationPrefix) => {
  return {
    series: getChartSeries(graphType, current, lastSeason, lastLastSeason),
    options: getChartOptions(graphType, current, lastSeason, lastLastSeason, translationPrefix),
  }
}

const getChartSeries = (graphType, current, lastSeason, lastLastSeason) => {
  return graphType === 'line'
    ? getLineChartSeries(current, lastSeason, lastLastSeason)
    : getDonutChartSeries(current)
}

const getChartOptions = (graphType, current, lastSeason, lastLastSeason, translationPrefix) => {
  return graphType === 'line'
    ? getLineChartOptions(current, lastSeason, lastLastSeason, translationPrefix)
    : getDonutChartOptions(current, translationPrefix)
}

const getLineChartSeries = (current, lastSeason, lastLastSeason) => {
  if (!current) return null

  let series = [];

  if (lastLastSeason) {
    series = [...series, {
      name: lastLastSeason.season,
      data: removeLeapYearDay(lastLastSeason.values),
    }]
  }

  if (lastSeason) {
    series = [...series, {
      name: lastSeason.season,
      data: removeLeapYearDay(lastSeason.values),
    }]
  }

  series = [...series,
    {
      name: current.season,
      data: removeLeapYearDay(current.values),
    }
  ]

  return series
}

const removeLeapYearDay = (data) => {
  if (data.length > 365) {
    // 59 is the index of 29th of february
    data.splice(59, 1);
  }
  return data
}

const getDonutChartSeries = (current) => {
  return current.values.map(value => parseFloat(value))
}

const getDonutChartOptions = (current, translationPrefix) => {

  const labels = current.points.map((point) => {
    const translationKey = translationPrefix ? `${translationPrefix}${point}` : point
    return i18n.t(translationKey)
  })

  return {
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatValue(value);
        }
      }
    },
    labels: labels,
    colors: ['#09C6AF', '#406BDF', '#FA364A', '#333333'] // TODO: set slopes difficulty colors
  }
}

const getLineChartOptions = (current, lastSeason, lastLastSeason, translationPrefix) => {
  if (!current) return null;
  let categories;

  if (lastLastSeason) {
    categories = lastLastSeason.points
  } else if (lastSeason) {
    categories = lastSeason.points
  } else {
    categories = current.points
  }

  categories = categories.map((category) => {
    const translationKey = translationPrefix ? `${translationsPrefix}${category}` : category
    return i18n.t(translationKey)
  })

  let colors = ['#6D62E4']

  if (lastSeason) {
    colors = ['#2FCDDC', ...colors]
  }

  if (lastLastSeason) {
    colors = ['#FFDD00', ...colors]
  }

  return {
    chart: {
      type: 'line',
      height: 350,
      zoom: {
        enabled: true
      }
    },
    // colors: [ '#EEDA8A', '#91BE83', '#3560DA', '#FDAFB7','#FF9F43', '#0C2340'],
    colors: colors,
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    title: {
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        format: 'dd/MM'
      }
    },
    yaxis: {
      title: {
        text: i18n.t(current.key)
      },
      labels: {
        formatter: function (value) {
          return formatValue(value);
        }
      }
    },
    legend: {
      showForSingleSeries: true,
      offsetY: 5,
      itemMargin: {
        vertical: 10
      },
    }
  }
}

const getCardData = (current, lastSeason, lastLastSeason) => {
  let cardData = [
    {
      name: current.season,
      value: formatValue(current.values[0])
    }
  ]

  if (lastSeason) {
    cardData = [...cardData, {
      name: lastSeason.season,
      value: formatValue(lastSeason.values[0])
    }]
  }

  if (lastLastSeason) {
    cardData = [...cardData, {
      name: lastLastSeason.season,
      value: formatValue(lastLastSeason.values[0])
    }]
  }

  return cardData
}

const getTotal = (total) => {
  return total ? formatValue(total.values[0]) : null
}

const formatValue = (value) => {

  const splittedValue = value.toString().split('.')
  const decimalCount = splittedValue.length === 1 ? 0 : splittedValue[1].length

  const options = {
    style: "decimal",
    minimumFractionDigits: decimalCount
  };

  value = new Intl.NumberFormat(navigator.language, options).format(parseFloat(value))

  return `${value}`.trim()
}

export {
  buildStatisticData
}
