var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.statisticData
    ? _c(
        "div",
        [
          _c(
            "vx-card",
            {
              staticClass: "h-full no-shadow",
              attrs: {
                title: _vm.statisticData.name(),
                subtitle: _vm.statisticData.resource.name
              }
            },
            [
              _vm.statisticData.total
                ? _c("div", [
                    _c("p", { staticClass: "font-bold mb-1 text-xl" }, [
                      _vm._v(_vm._s(_vm.$t("statistics.total")))
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
                        },
                        [
                          _c("statistics-card-line", {
                            staticClass: "mb-base",
                            attrs: {
                              hideChart: "",
                              "icon-right": "",
                              "no-icon": "",
                              statistic: _vm.statisticData.total
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              _vm.statisticData.season
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "font-bold mb-1" }, [
                        _vm._v(_vm._s(_vm.$t("statistics.season")))
                      ]),
                      _vm.statisticData.season.card
                        ? _c(
                            "div",
                            { staticClass: "vx-row" },
                            _vm._l(_vm.statisticData.season.card, function(
                              card,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "vx-col w-full xs:w-1/2 sm:w-1/3 md:w-1/2 lg:w-1/2 xl:w-1/3"
                                },
                                [
                                  _c("statistics-card-line", {
                                    attrs: {
                                      hideChart: "",
                                      "icon-right": "",
                                      "no-icon": "",
                                      statistic: card.value,
                                      statisticTitle: card.name
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.statisticData.season.chart
                        ? _c("vue-apex-charts", {
                            staticClass: "py-6",
                            attrs: {
                              type: _vm.statisticData.type,
                              height: "250",
                              width: "100%",
                              options: _vm.statisticData.season.chart.options,
                              series: _vm.statisticData.season.chart.series
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.statisticData.daily
                ? _c(
                    "div",
                    [
                      _c("p", { staticClass: "font-bold" }, [
                        _vm._v(_vm._s(_vm.$t("statistics.daily")))
                      ]),
                      _vm.statisticData.daily.card
                        ? _c(
                            "div",
                            { staticClass: "vx-row" },
                            _vm._l(_vm.statisticData.daily.card, function(
                              card,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2"
                                },
                                [
                                  _c("statistics-card-line", {
                                    staticClass: "mb-base",
                                    attrs: {
                                      hideChart: "",
                                      "icon-right": "",
                                      "no-icon": "",
                                      statistic: card.value,
                                      statisticTitle: card.name
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.statisticData.daily.chart
                        ? _c("vue-apex-charts", {
                            ref: "chart",
                            staticClass: "py-6",
                            attrs: {
                              type: _vm.statisticData.type,
                              height: "250",
                              width: "100%",
                              options: _vm.statisticData.daily.chart.options,
                              series: _vm.statisticData.daily.chart.series
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }