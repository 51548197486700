import { render, staticRenderFns } from "./StatisticCard.vue?vue&type=template&id=6fb2857c&"
import script from "./StatisticCard.vue?vue&type=script&lang=js&"
export * from "./StatisticCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fb2857c')) {
      api.createRecord('6fb2857c', component.options)
    } else {
      api.reload('6fb2857c', component.options)
    }
    module.hot.accept("./StatisticCard.vue?vue&type=template&id=6fb2857c&", function () {
      api.rerender('6fb2857c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/Statistics/Components/StatisticCard.vue"
export default component.exports